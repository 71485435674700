<template>
    <v-col class="px-0 py-0 mb-5">
        <v-card class="mx-auto text-left elevation-0" max-width="400" tile v-if="sortedDates.length > 0">
            <v-list class="py-0">
                <v-list-item v-for="(event, index) in sortedDates" :key="index" class="pa-0 d-flex">
                    <v-list-item-content class="py-0 list-events position-relative">
                        <v-list-item-title class="pt-2">{{ getDayName(event.name_day) }}
                            <span v-if="event.isOnlive" class="nextSchedule">{{ $t('scheduleComponentCard.live')}}</span>
                            <span v-if="todayDay === event.name_day && event.expired && !event.isOnlive" class="nextSchedule">{{$t('scheduleComponentCard.today') }}</span>
                            <span v-if="nextSchedule == event.name_day && todayDay != event.name_day" class="nextSchedule"> {{ $t('scheduleComponentCard.next') }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ $t('harmony.start-time') }} {{ formatTime(event.start_time) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            {{ $t('harmony.end-time') }} {{ formatTime(event.end_time) }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="event.url" class="pb-2">
                            <a :href="event.url" target="_blank">{{ $t('harmony.link') }}</a>
                        </v-list-item-subtitle>

                        <v-btn outlined color="#8e84c0" class="text-black rounded-md py-4 mt-6 button-delete" small
                            :loading="loading" @click="deleteSchedule(event.id)">
                            <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                        <div v-if="!event.url" class="mb-2"></div>
                        <hr class="w-100" v-if="index < sortedDates.length - 1" />
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-col>

</template>
<script>
import axios from "@/axios.js";

export default {
    name: "ScheduleUpload",
    props: {
        selected: {
            type: String,
            required: false,
            default: "",
        },
        uploadEndpoint: {
            type: String,
            required: true,
        },
        cardSelected: {
            type: String,
            required: false,
            default: "",
        },
        siteId: {
            type: Number,
            required: false,
            default: 0,
        },
        schedule: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            file: null,
            imagePreview: null,
            uploading: false,
            uploadProgress: 0,
            imgSrc: null,
            loading: false,
            loadingSave: false,
            addSchedule: false,
            todayDay: this.setToday(),
            nextSchedule: this.setNextSchedule(),
            onLive: false,
            rules: {
                required: (value) => {
                    return !!value || this.$t("navbar.required") || 'Field is required';
                },
            },

        };
    },
    computed: {
        sortedDates() {
            this.setToday()
            this.setNextSchedule()
            return this.schedule
                .map(event => ({
                    id: event.id || "",
                    start_time: event.start_time || "",
                    end_time: event.end_time || "",
                    name_day: event.name_day || "",
                    url: this.urlFormat(event.url),
                    isOnlive: this.setOnlive(event.start_time, event.end_time, event.name_day).onlive,
                    expired: this.setOnlive(event.start_time, event.end_time, event.name_day).expired,
                }))
                .sort((a, b) => a.name_day - b.name_day);
        },
    },
    created() {
        this.setLanguage();
    },
    methods: {
        isEmpty() {
            let status
            if (this.schedule) {
                status = true
            }
            else {
                status = false
            }
            return status
        },
        formatTime(time) {
            if (!time) return 'No Time Available';
            return time.slice(0, 5);
        },
        getDayName(name_day) {
            const dayKeys = [
                'harmony.day-1',
                'harmony.day-2',
                'harmony.day-3',
                'harmony.day-4',
                'harmony.day-5',
                'harmony.day-6',
                'harmony.day-7'
            ];
            return this.$t(dayKeys[name_day - 1]) || "Dia invalido";
        },
        async uploadSchedule(newDetail) {
            const formData = new FormData();
            formData.append("card", this.cardSelected);
            formData.append("site_id", this.siteId);
            formData.append('name_day', newDetail.name_day);
            formData.append('start_time', newDetail.start_time);
            formData.append('end_time', newDetail.end_time);
            formData.append('url', newDetail.url || '');


            this.uploading = true;
            this.uploadProgress = 0;

            try {
                const response = await axios.post(this.uploadEndpoint, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    onUploadProgress: (progressEvent) => {
                        this.uploadProgress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                    },
                });

                this.uploading = false;
                this.uploadProgress = 0;
                this.imagePreview = null;
                this.file = null;

                if (response) {
                    this.$vs.notify({
                        title: this.$t("imageUpload.uploadCard-title"),
                        text: this.$t("imageUpload.uploadCard-text"),
                        color: "success",
                        icon: "check_box",
                        position: "bottom-center",
                        time: 5000,
                    });
                }
                this.$emit("updated-schedule");
            } catch (error) {
                this.uploading = false;
                this.uploadProgress = 0;
                this.imagePreview = null;
                this.file = null;
                this.$vs.notify({
                    title: this.$t("imageUpload.uploadError-title"),
                    text: this.$t("imageUpload.uploadError-text"),
                    color: "danger",
                    icon: "warning",
                    position: "bottom-center",
                    time: 5000,
                });
                console.error("Upload Error:", error);
            }
            this.setNextSchedule()
            this.setToday()
        },
        async deleteSchedule(id) {
            const formData = new FormData();
            formData.append("id", id);
            formData.append("site_id", this.siteId);

            this.loading = true;

            try {
                const response = await axios.post('/remove-schedule', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    onUploadProgress: (progressEvent) => {
                        this.uploadProgress = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        );
                    },
                });
                if (response) {
                    this.$vs.notify({
                        title: this.$t("imageUpload.uploadCard-title"),
                        text: this.$t("imageUpload.uploadCard-text"),
                        color: "success",
                        icon: "check_box",
                        position: "bottom-center",
                        time: 5000,
                    });
                }
                this.$emit("updated-schedule");
                this.loading = false;
            } catch (error) {
                this.uploading = false;
                this.uploadProgress = 0;
                this.imagePreview = null;
                this.file = null;
                this.$vs.notify({
                    title: this.$t("imageUpload.uploadError-title"),
                    text: this.$t("imageUpload.uploadError-text"),
                    color: "danger",
                    icon: "warning",
                    position: "bottom-center",
                    time: 5000,
                });
                console.error("Upload Error:", error);
            }
            this.setNextSchedule()
            this.setToday()
        },
        setLanguage() {
            const storedLocale = localStorage.getItem("locale");
            if (storedLocale) {
                this.$i18n.locale = storedLocale;
            } else {
                this.$i18n.locale = "es";
            }
        },
        setToday() {
            const today = (new Date().getDay() + 1).toString();
            return today;
        },
        setNextSchedule() {
            if (!Array.isArray(this.schedule) || this.schedule.length === 0) {
                this.nextSchedule = null;
                return;
            }
            const now = new Date();
            const todayDay = now.getDay() + 1;
            let nearestDayDifference = Infinity;

            this.schedule.forEach(event => {
                const { start_time, end_time } = event;
                const name_day = parseInt(event.name_day);

                // Convertir el formato "HH:MM" en horas y minutos
                const [startHour, startMinute] = start_time.split(':').map(Number);
                const [endHour, endMinute] = end_time.split(':').map(Number);

                const eventStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), startHour, startMinute);
                const eventEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), endHour, endMinute);

                let dayDifference = name_day - todayDay;
                if (dayDifference < 0) {
                    dayDifference += 7;
                }

                eventStart.setDate(now.getDate() + dayDifference);
                eventEnd.setDate(now.getDate() + dayDifference);

                // Revisar si el evento ya pasó o es el próximo
                if (eventStart > now && name_day != todayDay || eventEnd > now && name_day != todayDay) {
                    if (dayDifference < nearestDayDifference) {
                        nearestDayDifference = dayDifference;
                    }
                }


                if (eventStart < now && eventEnd > now && this.onLive) {
                    this.onLive = true
                }

            });
            const remaining = (nearestDayDifference !== Infinity ? nearestDayDifference : 7)
            if ((remaining + todayDay) > 7) {
                return this.nextSchedule = (remaining - (7 - todayDay));
            } else {
                return this.nextSchedule = (remaining + todayDay);
            }
        },
        urlFormat(url) {
            if (url) {
                return url.startsWith('http://') || url.startsWith('https://')
                    ? url
                    : 'https://' + url;
            }
            return url
        },
        setOnlive(start_time, end_time, name_day) {
            let active = false;
            let onlive = false;
            const now = new Date();
            const todayDay = now.getDay() + 1;

            // Convertir el formato "HH:MM" en horas y minutos
            const [startHour, startMinute] = start_time.split(':').map(Number);
            const [endHour, endMinute] = end_time.split(':').map(Number);

            const eventStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), startHour, startMinute);
            const eventEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), endHour, endMinute);

            let dayDifference = name_day - todayDay;
            if (dayDifference < 0) {
                dayDifference += 7;
            }

            eventStart.setDate(now.getDate() + dayDifference);
            eventEnd.setDate(now.getDate() + dayDifference);
            if (eventStart < now && eventEnd > now) {
                onlive =  true
            }
            if (eventEnd > now && name_day == todayDay){
                active = true
            }

            const result = {
                expired: active,
                onlive: onlive,
            }

            return result
        }
    },
};
</script>

<style scoped>
.v-btn ::v-deep .v-input--selection-controls__input {
    margin: 0;
}

.v-file-input .v-input__control {
    display: none;
}

.v-file-input .v-label {
    width: 100%;
    text-align: center;
}

.image-preview {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.icon-placeholder {
    font-size: 40px;
    color: #c9c9c9;
}

.uploading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.uploading-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #8e84c0;
    font-weight: bold;
    text-align: center;
    font-size: 13px;
}

.progress-bar {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.title-question {
    font-size: 18px !important;
    color: #4a5568;
    font-weight: 800;
    margin-top: 16px !important;
}

.buttons-card {
    padding-left: 32px !important;
    padding-right: 32px !important;
}

.card-actions {
    float: right;
}

.list-events .v-list-item__title {
    color: #4a5568 !important;
    font-size: 14px !important;
}

.list-events .v-list-item__subtitle {
    color: #828da1 !important;
    font-size: 10px !important;
}

.button-delete {
    cursor: pointer;
    color: #f07a7a;
    position: absolute !important;
    left: auto;
    right: 0;
    width: fit-content !important;
    margin-top: 0% !important;
}

@media (max-width: 600px) {
    .card-actions {
        padding-bottom: 12px !important;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-right: 0px !important;
        margin-left: 0px !important;
        float: none;
    }

    .buttons-card {
        padding-left: 16px !important;
        padding-right: 16px !important;
        margin-bottom: 10px;
        /* Añade un margen inferior para separarlos */
        text-align: center;
    }

    .title-question {
        text-align: center;
        /* Centra el texto de 'Recortar Imagen' */
        width: 100%;
    }
}

.nextSchedule {
    font-size: 10px;
    padding: 2px 5px;
    background-color: rgb(3, 218, 3);
    color: white;
    border-radius: 6px;
}
</style>


<style lang="scss">
.alert {
    margin: 0 !important;
    padding: 0 !important;
}

.v-dialog {
    width: fit-content !important;
}
</style>