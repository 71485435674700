<template>
  <v-container class="position-relative ma-0 pa-0 ">
    <div v-if="isOnLive != null" class="onlive">{{ $t('scheduleComponentCard.live') }}</div>
    <v-img :src="image" class="background-image" />
    <div class="bg-img"></div>
    <div class="background-container px-3">
      <div class="w-full contTiner"  v-if="!isOnLive">
        <div class="d-flex flex-row justify-center">
          <div class="colTimer">
            <span class="weekdays text-center">
              {{ $t('scheduleComponentCard.days') }}
            </span>
            <span class="countdown-display">
              {{ formattedDays }}
            </span>
          </div>

          <div class="w-0 h-fit px-0 py-0 d-flex mt-auto mb-1">
            <span class=" w-0 countdown-dots">
              :
            </span>
          </div>

          <div class="colTimer">
            <span class="weekdays text-center">
              {{ $t('scheduleComponentCard.hours') }}
            </span>
            <span class="countdown-display">
              {{ formattedHours }}
            </span>
          </div>

          <div class="w-0 h-fit px-0 py-0 d-flex mt-auto mb-1">
            <span class=" w-0 countdown-dots">
              :
            </span>
          </div>

          <div class="colTimer">
            <span class="weekdays text-center">
              {{ $t('scheduleComponentCard.minutes') }}
            </span>
            <span class="countdown-display">
              {{ formattedMinutes }}
            </span>
          </div>

          <div class="w-0 h-fit px-0 py-0 d-flex mt-auto mb-1">
            <span class=" w-0 countdown-dots">
              :
            </span>
          </div>

          <div class="colTimer">
            <span class="weekdays text-center">
              {{ $t('scheduleComponentCard.seconds') }}
            </span>
            <span class="countdown-display">
              {{ formattedSeconds }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="!isOnLive" class="countdown-text px-9">
        {{ $t('scheduleComponentCard.countdown_text', { user }) }} {{ isOnLive }}
      </div>
      <div v-if="isOnLive" class="countdown-text px-2 px-md-9 py-5 onLives">
        <span v-html="$t('scheduleComponentCard.onLive', { user })"></span>
      </div>
      <div class="weekdays">
        <span v-for="(day, index) in weekdays" :key="index" :class="{
          'highlightRed': isOnLive === index || '',
          'eventDay': eventsDays.has(index)
        }">
          <div class="dot"></div>
          <p class="mb-0">
            {{ getShortDayName(day) }}
          </p>
        </span>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'ScheduleComponentCard',
  props: {
    image: {
      type: String,
      required: true,
      default: ''
    },
    user: {
      type: String,
      default: ''
    },
    schedule: {
      type: Array,
      required: true
    }
  },
  watch: {
    schedule: {
      handler() {
        this.organizeDates();

      },
    }
  },
  data() {
    return {
      weekdays: [
        this.$t('harmony.day-1'),
        this.$t('harmony.day-2'),
        this.$t('harmony.day-3'),
        this.$t('harmony.day-4'),
        this.$t('harmony.day-5'),
        this.$t('harmony.day-6'),
        this.$t('harmony.day-7')
      ],
      countdownTime: 0,
      timeRemaining: 0,
      sortedDates: [],
      interval: null,
      startTime: 0,
      eventsDays: new Set(),
      isOnLive: null
    };
  },
  computed: {
    formattedDays() {
      const days = Math.floor(this.timeRemaining / (1000 * 60 * 60 * 24));
      return this.formatNumber(days);
    },
    formattedHours() {
      const hours = Math.floor((this.timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      return this.formatNumber(hours);
    },
    formattedMinutes() {
      const minutes = Math.floor((this.timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      return this.formatNumber(minutes);
    },
    formattedSeconds() {
      const seconds = Math.floor((this.timeRemaining % (1000 * 60)) / 1000);
      return this.formatNumber(seconds);
    },
  },
  methods: {
    getShortDayName(day) {
      return day.slice(0, 3);
    },
    setCountdownTime() {
      if (!Array.isArray(this.schedule) || this.schedule.length === 0) {
        this.countdownTime = 0;
        return;
      }

      const now = new Date();
      let foundNextEvent = false;
      const todayDay = now.getDay() + 1;
      let nearestTimeDifference = Infinity;

      this.sortedDates.forEach(event => {
        const { start_time, end_time } = event;
        const name_day = parseInt(event.name_day);

        // Convertir formato "HH:MM" en fechas del día actual
        const [startHour, startMinute] = start_time.split(':').map(Number);
        const [endHour, endMinute] = end_time.split(':').map(Number);

        const eventStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), startHour, startMinute);
        const eventEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), endHour, endMinute);

        let dayDifference = name_day - todayDay;
        if (dayDifference < 0) {
          dayDifference += 7;
        }
        eventStart.setDate(now.getDate() + dayDifference);
        eventEnd.setDate(now.getDate() + dayDifference);

        // Comparar las fechas con el tiempo actual
        if (eventStart > now) {
          const timeDifference = eventStart - now;
          if (timeDifference > 0 && timeDifference < nearestTimeDifference) {
            nearestTimeDifference = timeDifference;
            foundNextEvent = true;
          }
        } else if (eventEnd > now) {
          const timeDifference = eventEnd - now;
          if (timeDifference > 0 && timeDifference < nearestTimeDifference) {
            nearestTimeDifference = timeDifference;
            foundNextEvent = true;
          }
        }
        if (eventStart < now && eventEnd > now && todayDay === name_day) {
          this.isOnLive = name_day - 1;
        } else if (eventStart < now && eventEnd < now) {
          this.isOnLive = null;
        }

      });

      if (!foundNextEvent) {
        nearestTimeDifference = 7 * 24 * 60 * 60 * 1000; // 7 días en milisegundos
      }

      this.countdownTime = nearestTimeDifference !== Infinity ? nearestTimeDifference : 0;
    },

    organizeDates() {
      this.isOnLive = null;
      const now = new Date();
      const today = now.toISOString().slice(0, 10);

      this.sortedDates = this.schedule
        .map(event => {
          const startDateTime = new Date(`${today}T${event.start_time}`);
          const endDateTime = new Date(`${today}T${event.end_time}`);
          const formatTime = (date) => date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit' });

          return {
            start_time: formatTime(startDateTime),
            end_time: formatTime(endDateTime),
            url: event.url || "",
            name_day: event.name_day
          };
        })
        .sort((a, b) => a.start_time.localeCompare(b.start_time));

      this.setCountdownTime();
      this.eventsDays.clear();

      this.sortedDates.forEach(event => {
        const eventDay = parseInt(event.name_day) - 1; // Ajusta el día del evento
        this.eventsDays.add(eventDay);
      });

      if (this.interval) {
        clearInterval(this.interval);
      }
      this.startTime = Date.now();
      this.interval = setInterval(this.updateCountdown, 1000);
    },

    calculateTimeDifference(time1, time2) {
      const dateTime1 = new Date(`1970-01-01T${time1}Z`);
      const dateTime2 = new Date(`1970-01-01T${time2}Z`);
      const diffInMs = dateTime2 - dateTime1;
      const diffInHours = diffInMs / (1000 * 60 * 60);

      return diffInHours;
    },

    formatNumber(value) {
      return String(value).padStart(2, '0');
    },

    updateCountdown() {
      this.timeRemaining = this.countdownTime - (Date.now() - this.startTime);
      if (this.timeRemaining <= 0) {
        clearInterval(this.interval);
        this.timeRemaining = 0;
        this.organizeDates();
        this.$emit('countdownComplete', this.isOnLive);
      }
    },
  },
  mounted() {
    this.organizeDates();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>

<style scoped>
.background-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.bg-img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4;
  background: rgba(0, 0, 0, 0.5)
}

.background-image {
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.countdown-text {
  height: fit-content;
  font-size: 0.5rem;
  text-transform: uppercase;
  color: white;
}

.weekdays {
  font-size: 0.4rem;
  color: white;
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  gap: 10px;
}

.countdown-display,
.countdown-dots {
  height: fit-content;
  font-size: 1.5rem;
  font-weight: 200;
  line-height: 1;
  letter-spacing: -2px;
  color: rgb(1, 212, 212);
  text-wrap: nowrap;
}

.countdown-dots {
  width: 0%;
  margin-bottom: 0px;
}

.h-fit {
  height: fit-content;
}

.eventDay {
  color: rgb(1, 212, 212);
  font-weight: 600;
  text-shadow: 0px 0px 2px rgb(1, 212, 212);
}

.highlightRed {
  color: red !important;
  font-weight: 600;
  text-shadow: 0px 0px 2px red !important;
}

.highlightRed .dot {
  background: red !important;
  -webkit-box-shadow: 0px 0px 10px 3px red;
  -moz-box-shadow: 0px 0px 10px 3px red;
  box-shadow: 0px 0px 10px 3px red;
}


.eventDay .dot {
  background: rgb(1, 212, 212);
}


.w-0 {
  font-size: 1.5rem;
  padding: 0px 3px;
  width: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 600px) {
  .countdown-display {
    font-size: 2.5rem;
  }

  .countdown-dots {
    margin-bottom: 5px;
  }

  .weekdays {
    font-size: 0.6rem;
  }

  .dot {
    width: 5px !important;
    height: 5px !important;
  }

  .countdown-text {
    font-size: 0.7rem;
  }

  .w-0 {
    font-size: 2rem !important;
  }

  .colTimer {
    width: 22% !important;
  }
}

.colTimer {
  width: 25%;
}

.contTiner {
  width: 100% !important;
  display: flex;
  flex-direction: column;
}

.dot {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  margin: auto;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(1, 212, 212.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(1, 212, 212.75);
  box-shadow: 0px 0px 5px 0px rgba(1, 212, 212.75);
}

.onLives {
  font-size: 0.7rem;
}

.onLives span {
  text-transform: uppercase !important;
}

.onlive {
  display: flex !important;
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  margin: 5px;
  padding: 3px 4px;
  font-weight: 500;
  font-size: 10px;
  line-height: 1;
  color: white;
  background-color: red;
  z-index: 100;
  border-radius: 20px;
  -webkit-box-shadow: 0px 0px 5px 0px red;
  -moz-box-shadow: 0px 0px 5px 0px red;
  box-shadow: 0px 0px 5px 0px red;
}
</style>